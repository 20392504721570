import request from '@/utils/request';

export function getChannelTree(data) {
  return request({
    url: 'misc-web-api/admin/channel/getChannelTree',
    method: 'post',
    data,
  });
}
export function getChannel(data) {
  return request({
    url: 'misc-web-api/admin/channel/getChannelList',
    method: 'post',
    data,
  });
}
export function addFirmChannel(data) {
  return request({
    url: 'supplier-web-api/admin/firm/addFirmChannel',
    method: 'post',
    data,
  });
}
export function getFirmChannelList(data) {
  return request({
    url: 'supplier-web-api/admin/firm/getFirmChannelList',
    method: 'post',
    data,
  });
}

export function getChannelTreeInfo(data) {
  return request({
    url: 'misc-web-api/admin/channel/getChannelTreeInfo',
    method: 'post',
    data,
  });
}
