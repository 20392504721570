<template>
  <div class="addStore-container">
    <el-form :inline="true" :model="storeForm" ref="addStoreForm" class="demo-form-inline">
      <el-form-item label="门店ID">
        <el-input v-model="storeForm.storeId" placeholder="请输入门店ID"></el-input>
      </el-form-item>
      <el-form-item label="门店名称">
        <el-input v-model="storeForm.storeName" placeholder="请输入门店名称"></el-input>
      </el-form-item>
      <el-form-item label="所属城市">
        <el-select v-model="storeForm.cityId" placeholder="请选择城市" filterable>
          <el-option
            v-for="(item, i) in citys"
            :key="i"
            :label="item.districtName"
            :value="item.districtId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="getData">查询</el-button>
        <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      element-loading-text="拼命加载中"
      v-loading="loading"
      @selection-change="handleSelectionChange"
      :data="tableData"
      ref="tableData"
      row-key="storeId"
      border
      class="managefirmstyle"
    >
      <el-table-column type="selection" reserve-selection width="50"></el-table-column>
      <el-table-column prop="storeId" label="门店ID"></el-table-column>
      <el-table-column prop="storeName" width="500" label="门店名称"></el-table-column>
      <el-table-column prop="cityName" label="所属城市"></el-table-column>
    </el-table>
    <!-- <el-pagination v-if="pageShow" :page-size="pageSize" class="mt-20" @current-change="pageChange" background
      layout="prev, pager, next" :total="total">
    </el-pagination> -->

    <!-- 翻页- -->
    <el-pagination
      class="pagination"
      background
      layout="total, prev, pager, next, sizes, jumper"
      :total="total"
      :page-sizes="[10, 50, 100, 200]"
      @size-change="handleSizeChange"
      @current-change="pageChange"
      :page-size="pageSize"
      :current-page="pageNumber"
    ></el-pagination>
  </div>
</template>
<script>
import { getStoreQuery, getCitys } from '@/api/store';
import { addFirmStoreList } from '@/api/operateParty';
export default {
  name: 'AddStore',
  props: {
    firmId: {
      type: Number,
      required: true,
      default: 1,
    },
    isDisable: {
      required: true,
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      pageShow: false,
      storeForm: {
        storeId: '',
        storeName: '',
        cityId: '',
      },
      pageNumber: 1,
      pageSize: 10,
      tableData: [],
      loading: false,
      total: 10,
      citys: [],
      storeIds: [],
    };
  },
  methods: {
    reset() {
      // this.$refs.addStoreForm.resetFields()
      this.storeForm = {
        storeId: '',
        storeName: '',
        cityId: '',
      };
      this.pageNumber = 1;
      this.pageSize = 10;
      this.getData();
      this.getCity();
    },
    // 获取城市信息
    async getCity() {
      try {
        const res = await getCitys({ data: { districtType: 2, status: 1 } });
        this.citys = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    // 获取门店列表信息
    async getData() {
      const data = {
        data: {
          export: 2,
          ...this.storeForm,
          neFirmId: this.firmId,
          isDisable: this.isDisable,
        },
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        const res = await getStoreQuery(data);
        const { content, total } = res.data;
        this.total = total ? Number(total) : 1;
        this.tableData = content ? content : [];
        this.storeIds = [];
        this.$nextTick(() => {
          this.$refs.tableData.clearSelection();
        });
        this.tableData.map((a) => {
          if (a.isRelationFirm == 1) {
            this.$nextTick(() => {
              this.$refs.tableData.toggleRowSelection(a);
            });
          }
        });
        this.loading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async addStore() {
      try {
        const data = {
          firmId: this.firmId,
          storeIds: this.storeIds,
        };
        const res = await addFirmStoreList({ data });
        res.success && this.$emit('closeAddStore');
      } catch (error) {
        console.log(error);
      }
    },
    handleSelectionChange(e) {
      if (e.length > 0) {
        this.storeIds = e.map((item) => item.storeId);
      } else {
        this.storeIds = [];
      }
    },
    pageChange(value) {
      this.pageNumber = value;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    // 重置
    newReset() {
      this.storeForm = {
        storeId: '',
        storeName: '',
        cityId: '',
      };
    },
  },
};
</script>
<style scoped lang="scss">
.store-form {
  display: flex;
  flex-wrap: wrap;
}

.managefirmstyle {
  max-height: 370px;
  overflow: auto;
}

.mt-20 {
  margin-top: 20px;
}
</style>
