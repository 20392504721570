<template>
  <el-dialog
    title="添加销售顾问"
    width="600px"
    :visible="show"
    @close="onClose"
    @open="onOpen"
    :close-on-click-modal="false"
  >
    <div class="item">
      <div class="item__label">已添加销售顾问</div>
      <div class="item-content">
        <el-tag
          class="item-content__tag"
          :key="index"
          v-for="(tag, index) in salesObjList"
          closable
          @close="tagClose(tag, index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="item">
      <div class="item__label">添加销售顾问</div>
      <el-select-tree
        :data="saleUserList"
        :props="{
          children: 'children',
          label: 'name',
          value: 'userId',
        }"
        filterable
        clearable
        multiple
        accordion
        ref="tree"
        collapse-tags
        show-checkbox
        check-strictly
        check-on-click-node
        v-model="consultantUserId"
        placeholder="请选择"
        @change="onChange"
        @clear="onClear"
      />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">取 消</el-button>
      <el-button type="primary" @click="onConfirm" :loading="confirmLoading">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getSaleUserTree, addFirmSale, getFirmSaleList } from '@/api/store';
import ElSelectTree from 'el-select-tree';
export default {
  components: { ElSelectTree },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    firmId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      saleUserList: [],
      consultantUserId: [],
      salesObjList: [],
      confirmLoading: false,
    };
  },
  methods: {
    async onOpen() {
      await this.getSaleUserTree();
      await this.getFirmSaleList();
    },
    async getFirmSaleList() {
      const res = await getFirmSaleList({
        data: {
          firmId: this.firmId,
        },
      });
      const saleIdList = res.data.map((e) => e.saleId);

      let checkedSale = this.handleCheckedSale(this.saleUserList, saleIdList);
      this.salesObjList = [...checkedSale];
      checkedSale = checkedSale.map((e) => e.userId);
      this.consultantUserId = checkedSale;

      this.$refs.tree.setCheckedKeys(checkedSale, true);
    },
    handleCheckedSale(arr, sales = []) {
      const list = [];
      arr.forEach((el) => {
        if (sales.includes(el.userId)) list.push(el);
        if (el.children) {
          const child = this.handleCheckedSale(el.children, sales);
          if (child.length) list.push(...child);
        }
      });
      return list;
    },
    onClose() {
      this.$emit('update:show', false);
      this.onClear();
    },
    tagClose(e, index) {
      this.salesObjList.splice(index, 1);
      console.log(e);
      this.consultantUserId = this.consultantUserId.filter((el) => el !== e.userId);
      this.$refs.tree.setCheckedKeys(this.consultantUserId);
    },
    onClear() {
      this.salesObjList = [];
      this.consultantUserId = [];
    },
    onChange(e) {
      console.log(e);
      this.salesObjList = this.$refs.tree.getCheckedNodes();
    },
    onConfirm() {
      const saleList = this.salesObjList.map((e) => {
        return {
          saleId: e.userId,
          saleName: e.userName,
        };
      });
      this.confirmLoading = true;
      addFirmSale({
        data: {
          firmId: this.firmId,
          saleList,
        },
      })
        .then((res) => {
          console.log(res);
          this.confirmLoading = false;
          this.$message.success('设置销售顾问成功~');
          this.$emit('refresh');
          this.onClose();
        })
        .catch((e) => {
          this.confirmLoading = false;
          this.$message.error(e.description || '设置销售顾问失败~');
          console.log(e);
        });
    },
    /**
     * 获取销售顾问树
     */
    async getSaleUserTree() {
      const res = await getSaleUserTree({ data: {} });
      const arr = this.hanldeSaleUserTree(res.data);
      this.saleUserList = arr;
    },
    hanldeSaleUserTree(list) {
      return list.map((e) => {
        e.children = [];
        // e.id = e.departmentId + '_' + e.userId;
        if (e.childrenList) e.userId = e.departmentId; // 父节点只有部门ID，子节点只有用户ID，拍平处理
        e.name = e.departmentName;
        e.type = 1;
        e.disabled = !e.userName;
        if (e.childrenList) {
          e.children = this.hanldeSaleUserTree(e.childrenList);
        }

        if (e.userList) {
          const userList = e.userList.map((i) => {
            return {
              ...i,
              // id: e.departmentId + '_' + i.userId,
              disabled: i.firmId !== '0' && i.firmId !== this.firmId, //判断是否已经关联过经营方，并且当前经营方是否就是关联的经营方。如果是则禁用。
              name: i.userName,
              type: 2,
            };
          });
          e.children = [...e.children, ...userList];
        }
        return e;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  margin-bottom: 20px;
  &__label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &-content {
    min-height: 40px;
    &__tag {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
