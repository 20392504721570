<template>
  <el-dialog
    title="添加渠道"
    width="600px"
    :visible="show"
    @close="onClose"
    @open="onOpen"
    :close-on-click-modal="false"
  >
    <div class="item">
      <div class="item__label">已添加渠道</div>
      <div class="item-content">
        <el-tag
          class="item-content__tag"
          :key="tag.channelTypeId"
          v-for="(tag, index) in channelObjList"
          closable
          @close="tagClose(tag, index)"
        >
          {{ tag.channelTypeName }}
        </el-tag>
      </div>
    </div>
    <div class="item">
      <div class="item__label">添加渠道</div>
      <el-select-tree
        :data="channelList"
        :props="{
          label: 'channelTypeName',
          child: 'children',
          value: 'channelTypeId',
        }"
        clearable
        v-model="channelId"
        multiple
        filterable
        accordion
        ref="tree"
        collapse-tags
        show-checkbox
        check-strictly
        check-on-click-node
        placeholder="请选择"
        @change="channelTypeChange"
        @clear="onClear"
        id="channelSelect"
      />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">取 消</el-button>
      <el-button type="primary" @click="onConfirm" :loading="confirmLoading">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addFirmChannel, getFirmChannelList, getChannelTreeInfo } from '@/api/misc.js';
import ElSelectTree from 'el-select-tree';
export default {
  components: { ElSelectTree },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    firmId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      channelList: [],
      channelId: [],
      channelTags: [],
      channelObjList: [], //懒加载无法拿到完整的列表
      confirmLoading: false,
    };
  },
  mounted() {},
  methods: {
    onConfirm() {
      const channelIds = this.channelId.map((e) => e.split('-')[0]);
      this.confirmLoading = true;
      addFirmChannel({
        data: {
          firmId: this.firmId,
          channelIds: channelIds,
        },
      })
        .then(() => {
          this.confirmLoading = false;
          this.$message.success('设置渠道成功~');
          this.$emit('refresh');
          this.onClose();
        })
        .catch((e) => {
          this.$message.error(e.description || '设置渠道失败~');
          this.confirmLoading = false;
        });
    },
    async onOpen() {
      await this.getChannelTypeList();
      await this.getFirmChannelList();
    },
    onClose() {
      this.$emit('update:show', false);
      this.onClear();
    },
    channelTypeChange() {
      this.channelObjList = this.$refs.tree.getCheckedNodes();
    },
    async getFirmChannelList() {
      const res = await getFirmChannelList({
        data: {
          firmId: this.firmId,
        },
      });
      this.channelId = res.data.map((e) => `${e.channelId}-leaf`);
      this.$refs.tree.setCheckedKeys(this.channelId, true);
      this.channelObjList = res.data.map((e) => {
        e.channelTypeId = `${e.channelId}-leaf`;
        e.channelTypeName = e.channelName;
        return e;
      });
      console.log(res);
    },
    tagClose(e, index) {
      this.channelObjList.splice(index, 1);
      this.channelId = this.channelId.filter((el) => el !== e.channelTypeId);
      this.$refs.tree.setCheckedKeys(this.channelId);
    },
    onClear() {
      this.channelObjList = [];
      this.channelId = [];
    },
    getSelectedChannel(list) {
      return list.map((el) => {
        el.disabled = true;

        if (el.children && el.children.length) {
          el.children = this.getSelectedChannel(el.children);
        }
        if (el.channelInfo && el.channelInfo.length) {
          el.channelInfo = el.channelInfo.map((e) => {
            return {
              ...e,
              channelTypeName: e.channelName,
              channelTypeId: e.id + '-' + 'leaf', //渠道本身没有channelTypeId字段，所以为了有一个node-key手动拼一个
            };
          });
          el.children = [...el.children, ...el.channelInfo];
        }
        return el;
      });
    },
    async getChannelTypeList() {
      try {
        const res = await getChannelTreeInfo({ data: {} });
        res.data = this.getSelectedChannel(res.data || []);
        this.channelList = res.data;

        this.$forceUpdate();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  margin-bottom: 20px;
  &__label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &-content {
    min-height: 40px;
    &__tag {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
