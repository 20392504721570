<template>
  <main class="operate-container">
    <el-form :model="form" class="form-style">
      <el-row :gutter="32" type="flex">
        <el-col :span="6">
          <div class="form-item">
            <span class="form-item-label">经营方ID</span>
            <el-input
              v-model="form.firmId"
              size="small"
              placeholder="请输入经营方ID"
              class="width100"
            >
              <template #append></template>
            </el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="form-item">
            <span class="form-item-label">经营方名称</span>
            <el-input
              v-model="form.firmName"
              size="small"
              placeholder="请输入经营方名称"
              class="width100"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="form-item">
            <span class="form-item-label">启用状态</span>
            <el-select
              v-model="form.isDisable"
              size="small"
              placeholder="请选择状态"
              clearable
              class="width100"
            >
              <el-option label="全部" value="null"></el-option>
              <el-option label="启用" value="0"></el-option>
              <el-option label="停用" value="1"></el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="form-item-handle">
            <el-button type="primary" size="small" icon="el-icon-search" @click="getDataCX">
              查询
            </el-button>
            <el-button size="small" icon="el-icon-refresh-left" @click="reset">重置</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <section class="section">
      <div class="button-container">
        <el-button icon="el-icon-plus" class="btn" type="primary" @click="handleAdd">
          新建
        </el-button>
      </div>
      <el-table
        class="x-styles"
        element-loading-text="拼命加载中"
        v-loading="loading"
        header-row-class-name="table-head"
        highlight-current-row
        :data="tableData"
        style="width: 100%"
        border
        :cell-style="columnbackgroundStyle"
      >
        <el-table-column align="center" prop="firmId" label="经营方ID" width="80"></el-table-column>
        <el-table-column
          align="center"
          prop="firmName"
          label="经营方名称"
          min-width="200"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="firmContactUser"
          label="联系人"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="firmContactPhone"
          label="联系电话"
          width="120"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="firmStoreNum"
          label="门店数量"
          width="90"
          trigger="hover"
        >
          <template slot-scope="scope">
            <span
              class="product-name"
              :class="scope.row.firmStoreNum > 0 ? 'product-name-click' : ''"
              @click="gotoOtherPage(scope.row)"
            >
              {{ scope.row.firmStoreNum }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="firmChannelNum"
          label="渠道数量"
          width="90"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="firmSaleNum"
          label="销售顾问数量"
          width="100"
        ></el-table-column>
        <el-table-column align="center" prop="isDisable" label="状态" width="90">
          <template slot-scope="scope">
            <span :class="scope.row.isDisable ? 'stopstyle' : 'enable'">
              {{ scope.row.isDisable ? '已停用' : '已启用' }}
            </span>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleToggle(scope.row)" type="text" size="small">
              {{ !scope.row.isDisable ? '停用' : '启用' }}
            </el-button>
            <!-- <el-button v-if="!scope.row.isDisable" @click="handleAddStore(scope.row)" type="text"
              size="small">添加门店</el-button> -->

            <el-button
              v-if="scope.row.isDisable"
              @click="handleEdit(scope.row)"
              type="text"
              size="small"
              :class="!scope.row.isDisable ? 'editstyle' : ''"
            >
              编辑
            </el-button>
            <el-dropdown v-if="!scope.row.isDisable" @command="onMore($event, scope.row)">
              <el-button style="margin-left: 10px" type="text" size="small">
                更多
                <i class="el-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="store">添加门店</el-dropdown-item>
                <el-dropdown-item command="channel">添加渠道</el-dropdown-item>
                <el-dropdown-item command="salesman">添加销售</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <!-- 翻页 -->
      <el-pagination
        class="pagination"
        background
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
        :page-sizes="[10, 50, 100, 200]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
      ></el-pagination>

      <!-- dialog 新增编辑对话框 -->
      <el-dialog
        :close-on-click-modal="false"
        @open="handleOpen"
        :title="dialogTitle + '经营方'"
        :visible.sync="isShowAddModal"
        width="500px"
      >
        <AddOrEdit @close="closeAllDialog" ref="addOrEdit" :isEdit="isEdit" :id="Number(id)" />
        <template #footer>
          <el-button @click="isShowAddModal = false">取消</el-button>
          <el-button type="primary" @click="handleConfirmAddEdit">完成</el-button>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-click-modal="false"
        @open="handleOpenAddStore"
        title="添加门店"
        :visible.sync="isShowAddStoreModal"
        width="1100px"
      >
        <AddStore
          @closeAddStore="closeAllDialog"
          :firmId="+firmId"
          :isDisable="isDisable"
          ref="addStore"
        />
        <template #footer>
          <el-button @click="isShowAddStoreModal = false">取消</el-button>
          <el-button type="primary" @click="handleConfirmAddStore">确定</el-button>
        </template>
      </el-dialog>

      <!-- 停用启用二次弹框- -->
      <el-dialog title="提示" :visible.sync="dialogVisibleTQ" width="22%">
        <span>是否确认{{ newRow.isDisable == 0 ? '停用' : '启用' }}？</span>
        <template #footer>
          <el-button @click="dialogVisibleTQ = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisibleTQOK">确 定</el-button>
        </template>
      </el-dialog>

      <!-- 关联渠道弹窗 -->
      <channel-dialog :show.sync="channelShow" :firmId="currentObj.firmId" @refresh="onRefresh" />
      <!-- 关联销售顾问弹窗 -->
      <salesman-dialog :show.sync="salesmanShow" :firmId="currentObj.firmId" @refresh="onRefresh" />
    </section>
  </main>
</template>
<script>
import { operateList, startAndstop } from '@/api/operateParty';
import AddOrEdit from './components/AddOrEdit/index.vue';
import AddStore from './components/AddStore/index.vue';
import ChannelDialog from './components/ChannelDialog/index.vue';
import SalesmanDialog from './components/SalesmanDialog/index.vue';

// 节流
function throttle(fn, delay) {
  let timer = null;
  return function () {
    if (timer) {
      return;
    }

    fn.apply(this, arguments);
    timer = setTimeout(() => {
      timer = null;
    }, delay);
  };
}

export default {
  name: 'OperateParty',
  components: {
    AddOrEdit,
    AddStore,
    ChannelDialog,
    SalesmanDialog,
  },
  data() {
    return {
      neFirmId: '',
      form: {
        firmId: null,
        firmName: '',
        isDisable: null,
      },
      firmId: 1,
      modalData: {},
      dialogTitle: '新增',
      isShowAddModal: false,
      isShowAddStoreModal: false,
      tableData: [],
      id: 0,
      isEdit: false,
      loading: true,
      pageNum: 1,
      pageSize: 10,
      total: 10,
      dialogVisibleTQ: false,
      newRow: '',
      isDisable: 0,
      channelShow: false,
      currentObj: {},
      salesmanShow: false,
    };
  },
  created() {
    this.form.firmId = this.$route.params.firmId;
    this.getData();
  },
  methods: {
    onRefresh() {
      this.getData();
    },
    onMore(e, row) {
      console.log(e);
      e === 'store' && this.handleAddStore(row);
      if (e === 'channel') {
        this.currentObj = row;
        this.channelShow = true;
      }
      if (e === 'salesman') {
        this.currentObj = row;
        this.salesmanShow = true;
      }
    },
    // 操作成功 提示
    successTips() {
      this.$message({
        message: '操作成功',
        type: 'success',
      });
    },
    // 门店数量跳转
    gotoOtherPage(data) {
      if (data.firmStoreNum) {
        // this.$router.push({
        //   path: '/storeList',
        //   query: {
        //     key: data.firmName,
        //     searchType: 5
        //   }
        // })
        this.$router.push({
          path: '/storeList',
          name: 'storeList',
          params: {
            key: data.firmName,
            searchType: '5',
          },
        });
      }
    },
    // 重置
    reset() {
      this.form = {
        firmId: null,
        firmName: '',
        isDisable: null,
      };
      this.pageNum = 1;
      this.pageSize = 10;
      this.getData();
    },
    // 查询
    getDataCX() {
      this.pageNum = 1;
      this.getData();
    },
    async getData() {
      try {
        this.loading = true;
        const data = {
          pageNumber: this.pageNum,
          pageSize: this.pageSize,
          fetchAll: true,
          data: {
            ...this.form,
          },
        };
        const res = await operateList(data);
        if (res.data) {
          const { content, total } = res.data;
          this.total = +total;
          this.tableData = content;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    handleEdit(data) {
      this.id = data.firmId;
      this.isEdit = true;
      if (data.isDisable) {
        this.isShowAddModal = true;
      }
      this.dialogTitle = '编辑';
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    // 添加经营方
    handleAdd() {
      this.dialogTitle = '新增';
      this.isShowAddModal = true;
      this.isEdit = false;
      this.$nextTick(() => {
        this.$refs.addOrEdit.resetForm();
      });
    },
    // 添加门店按钮
    handleAddStore(row) {
      const { firmId, isDisable } = row;
      this.firmId = firmId;
      this.isDisable = isDisable;
      this.isShowAddStoreModal = true;
    },
    closeAllDialog() {
      this.successTips();
      this.isShowAddStoreModal = false;
      this.isShowAddModal = false;
      this.getData();
    },
    // 关联门店弹窗
    handleConfirmAddStore() {
      this.$nextTick(() => {
        this.$refs.addStore.addStore();
        this.getData();
      });
    },
    handleOpen() {
      this.isEdit &&
        this.$nextTick(() => {
          this.$refs.addOrEdit.getDetail();
        });
    },
    // 经营方关联门店
    handleOpenAddStore() {
      this.$nextTick(() => {
        this.$refs.addStore.newReset();
        this.$refs.addStore.getCity();
        this.$refs.addStore.getData();
      });
    },
    // 新增/编辑
    handleConfirmAddEdit() {
      if (!this.isEdit) {
        this.$refs.addOrEdit.addOperate();
      } else {
        this.$refs.addOrEdit.editOperate();
      }
    },
    handleToggle(row) {
      this.dialogVisibleTQ = true;
      this.newRow = row;
    },
    // async dialogVisibleTQOK() {
    //   const { firmId: id } = this.newRow
    //   try {
    //     const res = await startAndstop({ data: id })
    //     res.data && this.getData()
    //     this.successTips()
    //   } catch (error) {
    //     console.log(error)
    //   }
    //   this.dialogVisibleTQ = false
    // },
    dialogVisibleTQOK: throttle(async function () {
      const { firmId: id } = this.newRow;
      try {
        const res = await startAndstop({ data: id });
        res.data && this.getData();
        this.successTips();
      } catch (error) {
        console.log(error);
      }
      this.dialogVisibleTQ = false;
    }, 1000),
    //修改table某列背景颜色
    columnbackgroundStyle() {
      // console.log(obj)
      // if (obj.columnIndex == 4) {
      //   return "color: #2861e2;";
      // }
    },
  },
};
</script>

<style scoped lang="scss">
@import url('./index.scss');
@import url('./header.scss');
</style>
