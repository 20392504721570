<template>
  <el-form ref="addOrEditForm" :rules="rules" :model="formData" label-width="100px">
    <el-form-item label="经营方名称" prop="firmName">
      <el-input v-model="formData.firmName"></el-input>
    </el-form-item>
    <el-form-item label="联系人" prop="firmContactUser">
      <el-input v-model="formData.firmContactUser"></el-input>
    </el-form-item>
    <el-form-item label="联系电话" prop="firmContactPhone">
      <el-input v-model="formData.firmContactPhone"></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
import { operateDetail, operateAdd, operateUpdate } from '@/api/operateParty'
export default {
  name: "AddOrEdit",
  props: {
    isEdit: {
      required: true,
      default: false,
      type: Boolean
    },
    id: {
      type: Number,
    }
  },
  data() {
    return {
      formData: {
        firmId: '',
        firmName: '',
        firmContactUser: '',
        firmContactPhone: '',
      },
      rules: {
        firmName: [
          { required: true, message: '请输入经营方名称', trigger: 'blur' },
        ],
        firmContactUser: [],
        firmContactPhone: []
      }
    }
  },
  methods: {
    async getDetail() {
      try {
        const res = await operateDetail({ data: this.id })
        this.formData = res.data
      } catch (error) {
        console.log(error)
      }
    },
    async addOperate() {
      this.formData.firmId = ''
      try {
        const res = await operateAdd({ data: this.formData })
        res.success && this.$emit('close')
      } catch (error) {
        console.log(error)
      }
    },
    // 更新（编辑）
    async editOperate() {
      try {
        const res = await operateUpdate({ data: this.formData })
        res.data && this.$emit('close')
      } catch (error) {
        console.log(error)
      }
    },
    resetForm() {
      this.$refs.addOrEditForm.resetFields()
    }
  }

}
</script>

<style scoped>
.elForm>>>.el-input__inner {
  width: 300px;
}
</style>